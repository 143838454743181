<template>
  <BaseDialog
    title="手動開立發票"
    width="600px"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div class="pb-[24px]">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="操作" prop="status">
          <BaseElSelect v-model="formData.status" prop="status" placeholder="請選擇">
            <BaseElSelectOption
              v-for="option in options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
      <p class="text-gray-60 text-sm">調整發票狀態後系統不會自動作廢或折讓發票，請自行至發票系統進行操作</p>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { MemberShopOrderInvalidInvoice, MemberShopOrderAllowanceAllInvoice } from '@/api/memberShop'
import formUtils from '@/utils/form'
import store from '@/store'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'MemberShopOrderInvoiceStatusEditModal',
  components: { BaseDialog },
  props: {
    orderId: { type: String, default: '' },
  },
  emits: ['done'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      status: null,
    })
    const formRules = {
      status: [noEmptyRules()],
    }

    const options = [
      { label: '標記作廢', value: 'invalid' },
      { label: '標記折讓', value: 'returned' },
    ]

    const invalidInvoice = async (payload) => {
      const [res, err] = await MemberShopOrderInvalidInvoice(payload)
      if (err) return window.$message.error(err)
      window.$message.success('發票已作廢！')
      emit('done')
    }
    const returnedInvoice = async (payload) => {
      const [res, err] = await MemberShopOrderAllowanceAllInvoice(payload)
      if (err) return window.$message.error(err)
      window.$message.success('發票已折讓！')
      emit('done')
    }

    const onConfirm = async () => {
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      const payload = {
        shopId: shopId.value,
        id: props.orderId,
      }
      if (formData.status === 'invalid') await invalidInvoice(payload)
      else if (formData.status === 'returned') await returnedInvoice(payload)
      loading.value = false
    }

    return { onConfirm, formData, formRules, formRef, options, loading }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply !w-full;
}
::v-deep .el-input__inner {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
</style>
