<template>
  <div>
    <GridInfoContainer title="發票資訊" labelWidth="120" labelGap="12">
      <template #default="slotData">
        <InfoContainerItem :data="slotData" label="發票狀態">
          <div class="flex items-center" style="gap: 22px">
            <Tag v-if="displayInvoiceStatus" :type="displayInvoiceStatus.tagType">{{ displayInvoiceStatus.label }}</Tag>
            <p v-if="!displayInvoiceStatus">{{ displayInvoiceStatus }}</p>
            <BaseElButton v-if="checkCode('ACT_INVOICE_RETURNED') && checkCode('ACT_INVOICE_VOIDED')" class="text-primary-100 underline" type="text" @click="modal.statusEdit = true">折讓/作廢</BaseElButton>
            <BaseElButton v-if="checkCode('ACT_INVOICE_ISSUED')" class="text-primary-100 underline" type="text" @click="modal.manualIssue = true">手動開立</BaseElButton>
          </div>
        </InfoContainerItem>
        <InfoContainerItem :data="slotData" label="發票種類" :value="displayIinvoiceType" />
        <InfoContainerItem :data="slotData" label="發票號碼" :value="get(order, 'MemberStoreOrderInvoice.invoiceNo') || '-'" />
      </template>
    </GridInfoContainer>

    <WarningDialog
      v-if="modal.manualIssue"
      title="手動開立發票"
      content="是否確認手動開立發票"
      @confirm="manualIssue"
      @close="modal.manualIssue = false"
    />

    <MemberShopOrderInvoiceStatusEditModal
      v-if="modal.statusEdit"
      :orderId="get(order, 'id')"
      @close="modal.statusEdit = false"
      @done="$emit('refresh'), modal.statusEdit = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import MemberShopOrderInvoiceStatusEditModal from './MemberShopOrderInvoiceStatusEditModal.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { get } from 'lodash'
import { invoiceStatusConfig } from '@/config/memberShop'
import { MemberShopOrderIssueInvoice } from '@/api/memberShop'
import Tag from '@/components/Tag/Tag.vue'
import store from '@/store'
import { useTransitionCode } from '../utils'

export default defineComponent({
  name: 'MemberShopOrderInvoiceInfoSection',
  components: { GridInfoContainer, InfoContainerItem, Tag, MemberShopOrderInvoiceStatusEditModal, WarningDialog },
  props: {
    order: { type: Object, default: () => ({}) },
    actions: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const displayInvoiceStatus = computed(() => {
      const status = get(props.order, 'MemberStoreOrderInvoice.status')
      if (!status) return null
      return get(invoiceStatusConfig, status)
    })
    const displayIinvoiceType = computed(() => {
      const invoiceNo = get(props.order, 'MemberStoreOrderInvoice.invoiceNo')
      if (invoiceNo) return '二聯式發票'
      return '-'
    })
    const checkCode = computed(() => {
      const actions = props.actions
      const { actionCode } = useTransitionCode(actions)
      return (code) => actionCode(code)
    })

    const modal = reactive({
      statusEdit: false,
      manualIssue: false,
    })

    const manualIssue = async () => {
      const [, err] = await MemberShopOrderIssueInvoice({
        shopId: shopId.value,
        id: get(props.order, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('已手動開立發票！')
      emit('refresh')
      modal.manualIssue = false
    }
    return { get, displayInvoiceStatus, modal, manualIssue, checkCode, displayIinvoiceType }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-[150px];
}
</style>
