<template>
  <BaseDialog
    title="提醒"
    width="610px"
    :btn1-loading="loading"
    @confirm="onConfirm"
    @close="$emit('close')"
    @cancel="$emit('close')"
  >
    <div>
      <p class="text-normal pb-[12px]">注意，點擊確認後，將針對以下商品申請退款：</p>
      <BaseTable :data="displayData" class="mb-[40px]">
        <BaseElTableColumn label="商品名稱" prop="itemName" align="center" />
        <BaseElTableColumn label="商品編號" prop="itemNo" align="center" />
        <BaseElTableColumn label="剩餘金額" prop="availableAmount" align="center" />
      </BaseTable>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { GetPaymentTrustOrderPreRefundDetail, MemberShopOrderRefund, RefundPaymentTrustOrder } from '@/api/memberShop'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'NewebpayTrustRefundModal',
  components: { BaseDialog },
  props: {
    paymentTrustOrderId: { type: String },
    orderId: { type: String },
  },
  setup (props, { emit }) {
    const detailData = ref([])
    const { shopId } = useShop()
    const loading = ref(false)

    const displayData = computed(() => {
      const itemDetails = get(detailData.value, 'itemDetails')
      return map(itemDetails, (item) => {
        return {
          ...item,
        }
      })
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await MemberShopOrderRefund({
        shopId: shopId.value,
        id: props.orderId,
      })
      // const [res, err] = await RefundPaymentTrustOrder({
      //   shopId: shopId.value,
      //   id: props.paymentTrustOrderId,
      // })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('申請退款成功！')
      emit('refresh')
      emit('close')
    }

    onBeforeMount(async () => {
      loading.value = true
      const [res, err] = await GetPaymentTrustOrderPreRefundDetail({
        shopId: shopId.value,
        id: props.paymentTrustOrderId,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      detailData.value = res
    })

    return { onConfirm, displayData, loading }
  },

})

</script>

<style lang="postcss" scoped>
</style>
